import * as React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import { graphql } from 'gatsby'
// import Button from 'components/Base/Button'
import SectionContent from 'components/SectionContent'
import SolutionsSwiper from 'components/SolutionsSwiper'
import SolutionsBanner from 'components/SolutionsBanner'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import DemoCardList from 'components/DemoCardList'
// import LogoWall from 'components/LogoWall'
import LogoWall from 'components/Home/LogoWall'
import PageBottomCard from 'components/PageBottomCard'
// import PartnerForm from 'components/PartnerForm'
import * as styles from './index.module.less'
import classNames from 'classnames'

// const { useState } = React

const Partners = (props) => {
  const { data } = props
  // const [showForm, handleForm] = useState(false)
  const logoWall = data.allHomeEnLogoWallYaml.nodes

  return (
    <Layout>
      <Seo
        title="合作伙伴招募｜神策数据"
        description="神策数据为全球500强企业和中国500强企业提供速数字化经营服务，为各行业营提供 “灵活、可靠、全面” 的数字化经营解决方案，期待你成为我们的合作伙伴。"
        keywords="神策数据渠道合作,数据分析服务商,数字化解决方案"
        saTitle="官网 - 渠道合作（合作伙伴计划）"
        meta={[
          {
            name: 'viewport',
            content:
              'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
          },
        ]}
      />
      <main className={styles.Partners}>
        <SolutionsBanner
          className="banner-wrap"
          title="神策数据合作伙伴计划"
          subTitle=""
          desc="“神策数据是一家志存高远的企业，希望能够和各路合作伙伴一起，帮助企业重构数据根基，实现数字化经营，给客户带来价值。” —— 神策数据 CEO 桑文锋"
          imgUrl={
            <img
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/icon-banner.png"
              alt="banner"
              className="banner-icon"
            />
          }
          buttonGroup={
            <>
              {/* {<Button btnType="default" style={{ marginRight: 20 }} onClick={() => handleForm(true)}>
                加入我们
              </Button>} */}
              <ScanCodeConsultButton
                buttonType="ghost"
                imgBottomText="微信扫码 立即咨询"
                ghost
                buttonText="伙伴咨询"
                qrcode="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/qrcode2.png"
                alt="伙伴咨询"
              />
            </>
          }
        />
        <SectionContent className="terms-wrap" title="为什么选择神策">
          <SolutionsSwiper
            className="partners-solutions-swiper"
            dataSource={[
              {
                className: 'advantage',
                title: '合作优势',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/advantage.png',
                descList: [
                  {
                    title: '神策品牌背书',
                    list: ['已服务全球 500 强及中国 500 强企业占比超过 10% ，业内公认赛道独角兽'],
                  },
                  {
                    title: '产品、服务成熟',
                    list: ['已合作超过 2000 家企业，近 100% 的续费率，获得大量客户好评，产品和服务体系完善'],
                  },
                  {
                    title: '受众面广',
                    list: ['已合作客户覆盖 30 多个行业，可运用于各行业大量业务场景'],
                  },
                  {
                    title: '合作产品灵活',
                    list: ['多款产品任意挑选，灵活满足客户在业务和预算上的多样需求'],
                  },
                ],
                qrButton: {
                  imgBottomText: '微信扫码 立即咨询',
                  buttonType: 'primary',
                  buttonText: '伙伴咨询',
                  qrcode: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/qrcode2.png',
                  alt: '伙伴咨询',
                },
                // buttonList: [
                //   {
                //     text: '加入我们',
                //     type: 'primary',
                //     onClick: () => handleForm(true),
                //   },
                // ],
              },
              {
                className: 'policy',
                title: '合作政策',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/policy.png',
                descList: [
                  {
                    title: '零负担，轻松启程',
                    list: ['只要您与神策需求匹配、方向一致，即可加入成为伙伴'],
                  },
                  {
                    title: '培训赋能，轻松上手',
                    list: [
                      '文档培训、视频培训、现场培训等，助力伙伴提升产品、业务理解；各行业标杆企业合作案例及解决法赋能伙伴应对客户',
                    ],
                  },
                  {
                    title: '项目支持，轻松落地',
                    list: [
                      '专职销售进行渠道支援，协助拿单；售前、分析师、运维工程师提供专业支持，推动伙伴项目交付落地',
                    ],
                  },
                ],
                qrButton: {
                  buttonType: 'primary',
                  buttonText: '伙伴咨询',
                  imgBottomText: '微信扫码 立即咨询',
                  qrcode: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/qrcode2.png',
                  alt: '伙伴咨询',
                },
                // buttonList: [
                //   {
                //     text: '加入我们',
                //     type: 'primary',
                //     onClick: () => handleForm(true),
                //   },
                // ],
              },
              {
                className: 'value',
                title: '合作价值',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/value.png',
                descList: [
                  {
                    title: '提高客单价',
                    list: ['为现有客户提供更多高价值的产品和服务，轻松实现每年利润增长'],
                  },
                  {
                    title: '提升拓客竞争力',
                    list: [
                      '结合神策能力，打造一套更全面而有力的数字化解决方案，在与同行竞争中占得先机，满足客户挑剔的数字化需求',
                    ],
                  },
                  {
                    title: '携手成长',
                    list: ['与神策共同提升数字化能力和意识，在这个数字化时代成为弄潮儿'],
                  },
                ],
                qrButton: {
                  buttonType: 'primary',
                  buttonText: '伙伴咨询',
                  imgBottomText: '微信扫码 立即咨询',
                  qrcode: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/qrcode2.png',
                  alt: '伙伴咨询',
                },
                // buttonList: [
                //   {
                //     text: '加入我们',
                //     type: 'primary',
                //     onClick: () => handleForm(true),
                //   },
                // ],
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="become-partner" title="成为神策合作伙伴，共享数字化发展红利">
          <div className="become-partner-content">
            <p className="content">
              您客户的业务是否正日渐线上化？
              <br />
              您客户的预算是否越来越多的投入到数字营销上？
              <br />
              您客户是否正经营着 App、小程序、公众号、H5 网站？
              <br />
              如何满足他们的数字化需求？
            </p>
            <h3 className="recruiting">神策数据开启大规模的渠道伙伴招募啦！！！</h3>
          </div>
          <div className="cooperation-steps">
            <img
              className="pc-show"
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/cooperation-steps.png"
              alt=""
            />
            <img
              className="mobile-show"
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/cooperation-steps-m.png"
              alt=""
            />
          </div>
          <div className="sd-button-group">
            {/* <Button btnType="default" onClick={() => handleForm(true)}>
              立即加入
            </Button> */}
            <ScanCodeConsultButton
              buttonType="white"
              imgBottomText="微信扫码 立即咨询"
              // ghost
              buttonText="伙伴咨询"
              qrcode="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/qrcode2.png"
              alt="伙伴咨询"
            />
          </div>
        </SectionContent>
        <SectionContent className="experience-center" title="体验中心">
          <p className="describe">
            神策数据围绕“客户旅程编排（Customer Journey
            Orchestration）”的产品理念，构建了客户数据引擎、客户旅程优化引擎、客户旅程分析引擎三大核心引擎，赋能企业的数字化客户经营。
          </p>
          <DemoCardList
            className="demo-card-list"
            dataSource={[
              {
                // iconUrl: require('assets/svgs/logo-analysis-cloud.svg').default,
                title: '客户数据引擎',
                descTitle: '实时、自助式、开放的 CDP',
                descList: [
                  '全域数据集成、打通，用户唯一识别',
                  '平台级实时能力，秒级端到端的数据流',
                  '自助式数据加工，业务人员自助标签建模',
                  '全面的开放能力，Open API 灵活能力扩展',
                ],
                buttonList: [
                  {
                    text: '了解更多',
                    target: '_blank',
                    href: '/engines/CDP.html',
                  },
                  {
                    text: '体验 Demo',
                    target: '_blank',
                    type: 'secondary',
                    href: '/demo/demo.html',
                  },
                ],
              },
              {
                // iconUrl: require('assets/svgs/logo-marketing-cloud.svg').default,
                title: '客户旅程分析引擎',
                descTitle: '全链路可视化分析洞察',
                descList: [
                  '从用户到经营的全链路分析',
                  '灵活的归因及下钻能力',
                  '分析结果的可视化呈现',
                  '可扩展的开放平台',
                ],
                buttonList: [
                  {
                    text: '了解更多',
                    target: '_blank',
                    href: '/engines/CJA.html',
                  },
                  {
                    text: '体验 Demo',
                    target: '_blank',
                    type: 'secondary',
                    href: '/doMatrixSa',
                  },
                ],
              },
              {
                // iconUrl: require('assets/svgs/logo-data-base.svg').default,
                title: '客户旅程优化引擎',
                descTitle: '个性化旅程互动和牵引',
                descList: ['灵活的用户旅程构建和优化能力', '全域触点的集成和联动能力', '强大的开放性与适配性'],
                buttonList: [
                  {
                    text: '了解更多',
                    target: '_blank',
                    href: '/engines/JOE.html',
                  },
                  {
                    text: '体验 Demo',
                    target: '_blank',
                    type: 'secondary',
                    href: '/doMatrixSf',
                  },
                ],
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="logos" title="为超过 2000 家企业提供大数据分析与营销科技服务">
          <LogoWall className={classNames('logo-wall', styles.pl)} dataSource={logoWall} />
        </SectionContent>
        <PageBottomCard
          title="数字化浪潮，就在当下！"
          desc="我们期待与您合作，携手帮助客户实现数据驱动，给客户带来价值！"
          // leftButtonText="加入我们，成为合作伙伴"
          qrButton={{
            buttonType: 'white',
            buttonText: '伙伴咨询',
            qrcode: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/terms/partners/qrcode2.png',
            alt: '伙伴咨询',
            imgBottomText: '微信扫码 立即咨询',
          }}
          // onClickLeftButton={() => handleForm(true)}
        />
      </main>
      {/* {showForm && <PartnerForm handleClose={() => handleForm(false)} />} */}
    </Layout>
  )
}
export const query = graphql`
  query {
    allHomeEnLogoWallYaml {
      nodes {
        name
        file {
          childImageSharp {
            gatsbyImageData(formats: AUTO, width: 142, height: 60, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default Partners
