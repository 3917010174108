import React from 'react'
import classnames from 'classnames'
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import * as styles from "./index.module.less";

interface LogoWallProps {
  className?: string;
  dataSource?: {name: string; file: IGatsbyImageData}[]
}
const LogoWall: React.FC<LogoWallProps> = props => {
  const {
    className,
    dataSource
  } = props;
  return (
    <ul className={classnames(styles.LogoWall, className)}>
      {dataSource?.map((item, index) => {
        const image = getImage(item.file)
        return <li key={index}>
          {image && <GatsbyImage image={image} alt="" />}
        </li>;
      })}
    </ul>
  );
}

export default LogoWall;